import {getUser} from "@/core/services/jwt.service";
import {format, register} from 'timeago.js';
import ApiService from "./api.service";
import moment from "moment";

const localeFunc = (number, index) => {
  return [
    ['vừa xong', 'right now'],
    ['%s giây trước', 'in %s seconds'],
    ['1 phút trước', 'in 1 minute'],
    ['%s phút trước', 'in %s minutes'],
    ['1 giờ trước', 'in 1 hour'],
    ['%s giờ trước', 'in %s hours'],
    ['1 ngày trước', 'in 1 day'],
    ['%s ngày trước', 'in %s days'],
    ['1 tuần trước', 'in 1 week'],
    ['%s tuần trước', 'in %s weeks'],
    ['1 tháng trước', 'in 1 month'],
    ['%s tháng trước', 'in %s months'],
    ['1 năm trước', 'in 1 year'],
    ['%s năm trước', 'in %s years']
  ][index];
}

register('vi_VN', localeFunc);

export const getWhereCondition = (filters) => {
  let where = "";
  let conditions = [];

  for (let key in filters) {
    let item = filters[key];
    conditions.push('{column: "' + key + '", value: "' + item.value + '", operator: ' + item.operator + '}');
  }

  if (conditions.length) {
    where = where + ",  where: {AND: [";
    where = where + conditions.join(", ");
    where = where + "]}";
  }

  return where;
};

export const cutString = (str, len = 10) => {
  if (str.length <= len) {
    return str;
  }

  // let tmp = str.split(".");
  // if (tmp.length > 1) {
  //   let ext = tmp[tmp.length - 1];
  //   return str.substring(0, len) + '...' + ext;
  // }

  return str.substring(0, len) + '...';
};

export const cutStringFileName = (str, len = 10) => {
  if (str.length <= len) {
    return str;
  }

  let tmp = str.split(".");
  if (tmp.length > 1) {
    let ext = tmp[tmp.length - 1];
    return str.substring(0, len) + '...' + ext;
  }

  return str.substring(0, len) + '...';
};

export const changeFavicon = link => {
  let $favicon = document.querySelector('link[rel="icon"]')
  if ($favicon !== null) {
    $favicon.href = link
  } else {
    $favicon = document.createElement("link")
    $favicon.rel = "icon"
    $favicon.href = link
    document.head.appendChild($favicon)
  }
}

export const openModal = id => {
  window.$("#" + id + "Open").click();
}

export const closeModal = id => {
  window.$("#" + id + "Close").click();
}

export const getSectionLessonCount = course => {
  let sectionCount = 0;
  let lessonCount = 0;

  if (course.sections && course.sections.length) {
    for (let i = 0; i < course.sections.length; i++) {
      sectionCount++;
      if (course.sections[i].lessons && course.sections[i].lessons.length) {
        for (let j = 0; j < course.sections[i].lessons.length; j++) {
          lessonCount++;
        }
      }
    }
  }

  return {
    lessonCount: lessonCount,
    sectionCount: sectionCount
  }
}

export const isMe = user => {
  let me = getUser();
  return (me && user.uid === me.uid)
}

export const convertOembed = (content) => {
  const oembed = content.split('</oembed>');
  let body = '';
  oembed.forEach((item, index) => {
    body += oembed[index] + '</oembed>';
    const oembed1 = item.split('url="')[1];
    if (oembed1) {
      const oembed2 = oembed1.split('">')[0];
      if (oembed2) {
        const youtube = oembed2.split('https://www.youtube.com/watch?v=')[1] || oembed2.split('https://youtu.be/')[1];
        const googleDocs = oembed2.split('https://docs.google.com')[1];
        const bcdcnt = oembed2.indexOf("bai-hat") > -1 || oembed2.indexOf("dan-ca") > -1 || oembed2.indexOf("khi-nhac") > -1 || oembed2.indexOf("thanh-vien-hat") > -1 || oembed2.indexOf("tieng-tho") > -1;
        if (youtube) {
          body += '<div class="iframe-wrap"><div class="iframe-container"><iframe style="position:absolute;top:0px;width:100%;height:100%;" src="https://www.youtube.com/embed/' + youtube + '" frameborder="0"; scrolling="no"; allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div></div>';
        } else if (googleDocs) {
          let url = oembed2.replace(/edit/g, "embed");
          body += '<div class="iframe-wrap"><div class="iframe-container"><iframe style="position:absolute;top:0px;width:100%;height:100%;" src="' + url + '" frameborder="0" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe></div></div>';
        } else if (bcdcnt) {
          body += '<div class="iframe-wrap"><div class="iframe-container"><iframe style="position:absolute;top:0px;width:100%;height:100%;"  frameborder="0" scrolling="no" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen src="' + window.location.origin + '/embed?src=' + oembed2 + '&class=iframe"></iframe></div><div class="flex justify-content-end"><a class="mt-2" title="Chi tiết bài hát" href="' + oembed2 + '">Link bài hát</a></div></div>';
        } else {
          body += '<div class="iframe-wrap"><div class="iframe-container"><iframe style="position:absolute;top:0px;width:100%;height:100%;" frameborder="0" scrolling="no" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen src="' + window.location.origin + '/embed?src=' + oembed2 + '&class=iframe"></iframe></div><div class="flex justify-content-end"><a target="_blank" class="mt-2" title="Tải về" href="' + oembed2 + '"><i class="uil uil-download-alt"></i></a></div></div>';
        }
      }
    }
  });
  return body;
}



export const lightBoxable = content => {
  let pattern = new RegExp(/<img src="(.*?)">/gi);
  content = content.replace(pattern, "<a href=\"$1\" class=\"lightbox\"><img src=\"$1\"></a>");
  return content;
}

export const stripTags = content => {
  return content.replace(/(<([^>]+)>)/gi, "");
}

export const stripContent = (content, length) => {
  content = stripTags(content);
  return cutString(content, length);
}

export const updateMe = from => {
  window.postMessage({action: "updateMe", from: from},
    window.location.origin);
}

export const reloadMe = from => {
  window.postMessage({action: "reloadMe", from: from},
    window.location.origin);
}

export const loadNotifications = () => {
  window.postMessage({action: "loadNotifications"},
    window.location.origin);
}

export const numberFormat = x => {
  let suffix = "";
  x = parseInt(x);

  if (x >= 1000000) {
    suffix = "Tr";
    x = (x / 1000000).toFixed(1);
    if (x.toString().indexOf(".0") > -1) {
      x = x.toString().replace(".0", "");
    }
  } else if (x >= 1000) {
    suffix = "N";
    x = (x / 1000).toFixed(1);
    if (x.toString().indexOf(".0") > -1) {
      x = x.toString().replace(".0", "");
    }
  }

  //return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + suffix;
  return x + suffix;
}

export const thousandFormat = x => {
  return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : "";
}

export const timeago = date => {
  return format(date, 'vi_VN');
}

export const convertDate = (date, format = "DD/MM/YYYY") => {
  if (date) {
    let dateObj = moment(date);
    if (dateObj) {
      return dateObj.format(format)
    }
  }

  return ""
}

export const convertLineBreak = str => {
  return str ? str.replace(/(?:\r\n|\r|\n)/g, '<br>') : "";
}

export const getRoutePrefixPeople = type => {
  switch (type) {
    case "artist":
      return "nghe-si";
    case "composer":
      return "nhac-si";
    case "recomposer":
      return "soan-gia";
    case "poet":
      return "nha-tho";
    default:
      return ""
  }
}

export const getNameFromPeoplePrefix = (prefix, type) => {
  switch (prefix) {
    case "nghe-si":
      if (type === "viet-nam") {
        return "Nghệ sĩ Việt Nam";
      }
      if (type === "nuoc-ngoai") {
        return "Nghệ sĩ nước ngoài";
      }
      return "Nghệ sĩ";
    case "nhac-si":
      if (type === "viet-nam") {
        return "Nhạc sĩ Việt Nam";
      }
      if (type === "nuoc-ngoai") {
        return "Nhạc sĩ nước ngoài";
      }
      return "Nhạc sĩ";
    case "soan-gia":
      return "Soạn giả";
    case "nha-tho":
      return "Nhà thơ";
    default:
      return ""
  }
}

export const getPluralFromPeoplePrefix = prefix => {
  switch (prefix) {
    case "nghe-si":
      return "artist";
    case "nhac-si":
      return "composer";
    case "soan-gia":
      return "recomposer";
    case "nha-tho":
      return "poet";
    default:
      return ""
  }
}

export const getPluralFromSongPrefix = prefix => {
  switch (prefix) {
    case "bai-hat":
      return "song";
    case "dan-ca":
      return "folk";
    case "khi-nhac":
      return "instrumental";
    case "thanh-vien-hat":
      return "karaoke";
    case "tieng-tho":
      return "poem";
    case "vov":
      return "vovProgram";
    default:
      return ""
  }
}

export const getEditRouterFromSongPrefix = prefix => {
  switch (prefix) {
    case "bai-hat":
      return "AdminSongEdit";
    case "dan-ca":
      return "AdminFolkEdit";
    case "khi-nhac":
      return "AdminInstrumentalEdit";
    case "thanh-vien-hat":
      return "AdminKaraokeEdit";
    case "tieng-tho":
      return "AdminPoemEdit";
    default:
      return ""
  }
}

export const getPrefixFromSongType = type => {
  type = type.toLowerCase();
  switch (type) {
    case "song":
      return "bai-hat";
    case "folk":
      return "dan-ca";
    case "instrumental":
      return "khi-nhac";
    case "karaoke":
      return "thanh-vien-hat";
    case "poem":
      return "tieng-tho";
    default:
      return ""
  }
}

export const getUploadTypeText = type => {
  switch (type) {
    case "song":
      return "Tân nhạc";
    case "folk":
      return "Dân ca";
    case "instrumental":
      return "Khí nhạc";
    case "karaoke":
      return "Thành viên hát";
    case "poem":
      return "Tiếng thơ";
    case "beat":
      return "Beat nhạc";
    case "replace":
      return "Thay thế";
    case "document_audio":
      return "Tư liệu âm thanh";
    case "document_image":
      return "Tư liệu hình ảnh";
    case "document_news":
      return "Tư liệu bài viết";
    case "sheet":
      return "Bản nhạc";
    case "discussion":
      return "Thảo luận";
    default:
      return ""
  }
}

export const getUploadTypeClass = type => {
  switch (type) {
    case "song":
      return "bg-green-700 text-white";
    case "folk":
      return "bg-purple-300 text-white";
    case "instrumental":
      return "bg-indigo-800 text-white";
    case "karaoke":
      return "bg-pink-400 text-white";
    case "poem":
      return "bg-yellow-400 text-white";
    case "beat":
      return "bg-red-900 text-white";
    case "replace":
      return "bg-blue-500 text-white";
    case "document_audio":
    case "document_image":
    case "document_news":
      return "bg-red-500 text-white";
    case "sheet":
      return "bg-yellow-500 text-white";
    default:
      return "bg-gray-600 text-white"
  }
}

export const getUploadStatusText = status => {
  switch (status) {
    case "approved":
      return "Đã sử dụng";
    case "rejected":
      return "Chưa sử dụng";
    default:
      return "Chờ duyệt"
  }
}

export const getUploadStatusClass = status => {
  switch (status) {
    case "approved":
      return "bg-green-500 text-white";
    case "rejected":
      return "bg-red-600 text-red-100";
    default:
      return "bg-gray-600 text-red-100"
  }
}

export const getChangeUsernameRequestStatusText = status => {
  switch (status) {
    case "approved":
      return "Đồng ý";
    case "rejected":
      return "Từ chối";
    default:
      return "Chờ duyệt"
  }
}

export const getChangeUsernameRequestStatusClass = status => {
  switch (status) {
    case "approved":
      return "bg-green-500 text-white";
    case "rejected":
      return "bg-red-600 text-red-100";
    default:
      return "bg-gray-600 text-red-100"
  }
}

export const getUploadTitle = prefix => {
  switch (prefix) {
    case "tan-nhac":
      return "Gửi tân nhạc";
    case "dan-ca":
      return "Gửi dân ca";
    case "khi-nhac":
      return "Gửi khí nhạc";
    case "thanh-vien-hat":
      return "Gửi thành viên hát";
    case "tieng-tho":
      return "Gửi tiếng thơ";
    case "beat-nhac":
      return "Gửi beat nhạc";
    case "thay-the-ban-thu":
      return "Thay thế bản thu";
    case "tu-lieu-am-thanh":
      return "Gửi tư liệu âm thanh";
    case "tu-lieu-hinh-anh":
      return "Gửi tư liệu hình ảnh";
    case "tu-lieu-bai-viet":
      return "Gửi tư liệu bài viết";
    case "sheet":
      return "Gửi bản nhạc";
    default:
      return ""
  }
}

export const getUploadType = prefix => {
  switch (prefix) {
    case "tan-nhac":
      return "song";
    case "dan-ca":
      return "folk";
    case "khi-nhac":
      return "instrumental";
    case "thanh-vien-hat":
      return "karaoke";
    case "tieng-tho":
      return "poem";
    case "beat-nhac":
      return "beat";
    case "thay-the-ban-thu":
      return "replace";
    case "tu-lieu-am-thanh":
      return "document_audio";
    case "tu-lieu-hinh-anh":
      return "document_image";
    case "tu-lieu-bai-viet":
      return "document_news";
    default:
      return prefix;
  }
}

export const getTicketStatusText = status => {
  switch (status) {
    case "init":
      return "Khời tạo";
    case "received":
      return "Đã tiếp nhận";
    case "processing":
      return "Đang xử lý";
    case "processed":
      return "Đã xử lý";
    case "closed":
      return "Đã đóng";
    default:
      return "Chưa rõ"
  }
}

export const getTicketStatusClass = status => {
  switch (status) {
    case "init":
      return "bg-gray-500 text-white";
    case "received":
      return "bg-blue-500 text-white";
    case "processing":
      return "bg-yellow-500 text-white";
    case "processed":
      return "bg-green-500 text-white";
    case "closed":
      return "bg-red-500 text-white";
    default:
      return "bg-green-500 text-white"
  }
}

export const getPeopleType = type => {
  type = type.toLowerCase();
  
  switch (type) {
    case "composer":
      return "Nhạc sĩ";
    case "recomposer":
      return "Soạn giả";
    case "artist":
      return "Nghệ sĩ";
    case "poet":
      return "Nhà thơ";
    default:
      return "";
  }
}

export const changePageTitle = title => {
  document.title = title;
}

export const changeMetaTag = (tag, content) => {
  if (tag === "description") {
    document.querySelector('meta[name="' + tag + '"]').setAttribute("content", content);
  }
  
  document.querySelector('meta[property="og:' + tag + '"]').setAttribute("content", content);
}

export const getUserLoves = lovable => {
  if (lovable.loves && lovable.loves.data) {
    let usernames = [];

    lovable.loves.data.map((item) => {
      if (item.user && item.user.username) {
        usernames.push(item.user.username);
      }
    });

    return usernames.join(", ");
  }

  return "";
}

export const hasLoved = (lovable, user) => {
  if (lovable.loves && lovable.loves.data && lovable.loves.data.length) {
    for (let i = 0; i < lovable.loves.data.length; i++) {
      if (user && lovable.loves.data[i].user && parseInt(user.id) === parseInt(lovable.loves.data[i].user.id)) {
        return true;
      }
    }
  }

  return false;
}

const songableTypes = ["Song", "Folk", "Instrumental", "Karaoke", "Poem"];
const peopleTypes = ["Composer", "Artist", "Poet", "Recomposer"];

export const isSongable = type => {
  return songableTypes.includes(type);
}

export const isPeople = type => {
  return peopleTypes.includes(type);
}

export const getPeopleNames = people => {
  var names = [];

  people.forEach(function (person) {
    names.push(person.title);
  });

  return names.length ? names.join(" - ") : "";
}

export const getNotificationRouteName = type => {
  switch (type) {
    case "artist":
    case "composer":
    case "recomposer":
    case "poet":
      return "PeopleDetail";
    case "song":
    case "folk":
    case "instrumental":
    case "karaoke":
    case "poem":
      return "SongDetail";
    case "document":
      return "DocumentDetail";
    case "discussion":
      return "DiscussionDetail";
    case "playlist":
      return "PlaylistDetail";
    case "page":
      return "PageDetail";
    case "sheet":
      return "SheetDetail";
    case "upload":
      return "UploadDetail";
    case "ticket":
      return "TicketDetail";
    case "role":
      return "RoleDetail";
    default:
      return type + "Detail";
  }
}

export const getNotificationRouteParams = (type, extra) => {
  switch (type) {
    case "artist":
    case "composer":
    case "recomposer":
    case "poet":
      return {slug: extra.slug, prefix: getRoutePrefixPeople(type)};
    case "song":
    case "folk":
    case "instrumental":
    case "karaoke":
    case "poem":
      return {id: extra.id, slug: extra.slug, prefix: getPrefixFromSongType(type)};
    case "playlist":
    case "discussion":
    case "document":
    case "sheet":
      return {id: extra.id, slug: extra.slug};
    case "role":
    case "page":
      return {slug: extra.slug};
    case "ticket":
    case "upload":
      return {id: extra.id};
    default:
      return {id: extra.id}
  }
}

export const getNotificationRouteQuery = (notification, extra) => {
  if (notification.object_type === "comment") {
    return {
      comment_id: notification.object_id
    }
  }

  if (extra && extra.comment_id) {
    return {
      comment_id: extra.comment_id
    }
  }

  return {};
}

export const findImageUrlsFromString = str => {
  const regex = /\b(https?:\/\/\S+(?:png|jpe?g|gif|ttf))\b/igm;
  let m;
  let urls = [];

  while ((m = regex.exec(str)) !== null) {
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }

    m.forEach((match, groupIndex) => {
      if (groupIndex === 0) {
        urls.push(match);
      }
    });
  }

  return urls;
}

export const getImplodeString = (objects, field) => {
  let tmp = [];

  for (let i = 0; i < objects.length; i++) {
    if (typeof objects[i][field] !== "undefined") {
      tmp.push(objects[i][field]);
    }
  }

  return tmp.join(" - ");
}

export const logActivity = (action, object_type, object_id) => {
  let query = `mutation($action: String!, $object_type: String!, $object_id: ID) {
        logActivity(action: $action, object_type: $object_type, object_id: $object_id) {id}
      }`;

  ApiService.graphql(query, {action: action, object_type: object_type, object_id: object_id})
    .then(() => {
      window.postMessage({action: "logActivityDone", value: {action: action, object_type: object_type, object_id: object_id}}, window.location.origin);
    })
    .catch((response) => {
      console.log(response);
    });
}

export const getYears = createdAt => {
  let date = new Date(createdAt);
  let currentYear = new Date().getFullYear();
  return currentYear - date.getFullYear();
}

export const getSongFieldsByType = (type, from) => {
  switch (type) {
    case "song":
      return from === "artist" ? `` :
      `artists(first: 100, orderBy: [{column: "song_artist.order", order: ASC}]) {
        data {
          id
          title
          slug
          avatar {
            url
          }
        }
      }
      `;
    case "folk":
      return `artists(first: 100, orderBy: [{column: "folk_artist.order", order: ASC}]) {
                    data {
                      id
                      title
                      slug
                      avatar {
                        url
                      }
                    }
                  }
                  composers(first: 100, orderBy: [{column: "folk_composer.order", order: ASC}]) {
                    data {
                      id
                      title
                      slug
                    }
                  }
                  recomposers(first: 100, orderBy: [{column: "folk_recomposer.order", order: ASC}]) {
                    data {
                      id
                      title
                      slug
                    }
                  }
                  fcats(first: 100) {
                    data {
                      id
                      title
                      slug
                    }
                  }
                  melodies(first: 100) {
                    data {
                      id
                      title
                      slug
                    }
                  }`;
    case "instrumental":
      return `artists(first: 100, orderBy: [{column: "instrumental_artist.order", order: ASC}]) {
                    data {
                      id
                      title
                      slug
                      avatar {
                        url
                      }
                    }
                  }`;
    case "poem":
      return `artists(first: 100, orderBy: [{column: "poem_artist.order", order: ASC}]) {
                    data {
                      id
                      title
                      slug
                      avatar {
                        url
                      }
                    }
                  }
                  poets(first: 100) {
                    data {
                      id
                      title
                      slug
                    }
                  }`;
    case "karaoke":
      return `users(first: 100) {
                    data {
                      id
                      username
                      avatar {
                        url
                      }
                    }
                  }
                  fcats(first: 100) {
                    data {
                      id
                      title
                      slug
                    }
                  }
                  melodies(first: 100) {
                    data {
                      id
                      title
                      slug
                    }
                  }`;
    default:
      return ``;
  }
}

export const makePlayTime = content => {
  if (content) {
    const regex = /([0-9]{2}):([0-9]{2})/igm;
    return content.replace(regex, '<a class="font-semibold color-href-primary" href="javascript:playTime(\'$&\')">$&</a>');
  }

  return "";
}

export const getArtistsOfSong = song => {
  let tmp = [];

  if (song.artists && song.artists.data && song.artists.data.length) {
    song.artists.data.map((artist) => {
      tmp.push(artist.title);
    });
  }

  if (song.user) {
    return song.user.username;
  }

  return tmp.join(" - ");
}

export const getThumbnailOfSong = song => {
  if (song.thumbnail && song.thumbnail.url) {
    return song.thumbnail.url;
  }

  if (song.artists && song.artists.data && song.artists.data.length) {
    for (let i = 0; i < song.artists.data.length; i++) {
      if (song.artists.data[i].avatar && song.artists.data[i].avatar.url) {
        return song.artists.data[i].avatar.url;
      }
    }
  }

  if (song.user && song.user.avatar && song.user.avatar.url) {
    return song.user.avatar.url;
  }

  return "https://static.bcdcnt.net/assets/images/group/group-3.jpg";
}

export const showNotification = (title, options) => {
  if (window.Notification && Notification.permission === 'granted') {
    new Notification(title, options);
  }
}

export const censorBadWords = (content) => {
  let regex = /(\b)(địt|buồi|lồn|cặc|dái|đĩ|đéo|thủ dâm|chịch|đụ)(\b)/gi;
  content = content.replace(regex, '***');

  return content;
}

export const isAdmin = () => {
  return isRole("admin");
}

export const isEditor = () => {
  return isRole("editor");
}

export const isAdminOfEditor = () => {
  return isRole("editor", "admin");
}

export const isCollaborator = () => {
  return isRole("collaborator");
}

export const isRole = (alias, level) => {
  let user = getUser();

  if (user && user.roles) {
    for (let i = 0; i < user.roles.length; i++) {
      if (typeof level !== "undefined") {
        if (user.roles[i].userRolePivot && user.roles[i].userRolePivot.role && user.roles[i].userRolePivot.role === level && user.roles[i].alias === alias) {
          return true;
        }
      } else {
        if (user.roles[i].alias === alias) {
          return true;
        }
      }
    }
  }

  return false;
}

export const canUploadSong = () => {
  let user = getUser();
  return isAdmin() || !!(user && user.can_upload === 1);
}

export const canEditSong = () => {
  return isAdmin() || isAdminOfEditor();
}

export const canManageSheet = () => {
  let user = getUser();
  return isAdmin() || !!(user && user.can_manage_sheet === 1);
}

export const canManageSong = () => {
  let user = getUser();
  return isAdmin() || isAdminOfEditor() || !!(user && user.can_manage_song === 1);
}

export const canManagePeople = () => {
  let user = getUser();
  return isAdmin() || isEditor() || !!(user && user.can_manage_people === 1);
}

export const canManageDocument = () => {
  let user = getUser();
  return isAdmin() || isEditor() || !!(user && user.can_manage_document === 1);
}

export const canManageUpload = () => {
  return isAdmin();
}

export const canManageTag = () => {
  let user = getUser();
  return isAdmin() || !!(user && user.can_manage_tag === 1);
}

export const canManageComment = () => {
  let user = getUser();
  return isAdmin() || !!(user && user.can_manage_comment === 1);
}

export const canManageThumbnail = () => {
  let user = getUser();
  return isAdmin() || !!(user && user.can_manage_thumbnail === 1);
}

export const canMarkHq = () => {
  let user = getUser();
  return isAdmin() || !!(user && user.can_mark_hq === 1);
}

export const canManageTicket = () => {
  return isAdmin();
}

export const canManageUser = () => {
  return isAdmin();
}

export const canUpdateLyric = () => {
  let user = getUser();
  return isAdmin() || !!(user && user.can_update_lyric === 1);
}

export const canUpdateThumbnail = () => {
  let user = getUser();
  return isAdmin() || !!(user && user.can_update_thumbnail === 1);
}

export const canUpdateAvatar = () => {
  let user = getUser();
  return isAdmin() || !!(user && user.can_update_avatar === 1);
}

export const isOwner = (user_id) => {
  let user = getUser();
  return !!(user_id && user && user.id.toString() === user_id);
}

export const isAdminOfRole = (role) => {
  let user = getUser();

  if (user && user.roles && user.roles.length) {
    for (let i = 0; i < user.roles.length; i++) {
      if (user.roles[i].id.toString() === role.id.toString() && user.roles[i].userRolePivot && user.roles[i].userRolePivot.role && user.roles[i].userRolePivot.role === "admin") {
        return true;
      }
      if (user.roles[i].alias === "admin") {
        return true;
      }
    }
  }

  return false;
}


export const showAlert = () => {
  window.$("#alert-modal").addClass("uk-modal uk-open").css({display: "block"});
  window.$("html").addClass("uk-modal-page");
}

export const getTicketCategories = () => {
  return [
    {
      "key": "GOP_Y",
      "label": "GÓP Ý"
    },
    {
      "key": "DONG_GOP_Y_TUONG",
      "label": "ĐÓNG GÓP Ý TƯỞNG"
    },
    {
      "key": "THAC_MAC_HOI_DAP",
      "label": "THẮC MẮC, HỎI ĐÁP"
    },
    {
      "key": "TRO_GIUP_KY_THUAT",
      "label": "TRỢ GIÚP KỸ THUẬT"
    },
    {
      "key": "OTHER",
      "label": "CÁC VẤN ĐỀ KHÁC"
    },
    {
      "key": "TAI_TRO",
      "label": "TÀI TRỢ CHO BCĐCNT",
      "sub": [
        {
          "key": "UNG_HO_CA_NHAN",
          "label": "Ủng hộ cá nhân"
        },
        {
          "key": "TAI_TRO_DOANH_NGHIEP",
          "label": "Tài trợ doanh nghiệp"
        },
        {
          "key": "TAI_TRO_CHINH_PHU",
          "label": "Tài trợ chính phủ"
        },
      ]
    },
    {
      "key": "CONG_TAC",
      "label": "CỘNG TÁC CÙNG BCĐCNT",
      "sub": [
        {
          "key": "CONG_TAC_TRUYEN_THONG",
          "label": "Ban truyền thông"
        },
        {
          "key": "CONG_TAC_PTST",
          "label": "Ban phát triển sáng tạo"
        },
        {
          "key": "CONG_TAC_CTV",
          "label": "Cộng tác viên các chuyên mục"
        },
      ]
    }
  ];
}

export const getTicketCategory = slug => {
  let categories = getTicketCategories();

  for (let i = 0; i < categories.length; i++) {
    let category = categories[i];
    if (!category.sub) {
      if (category.key === slug) {
        return category.label;
      }
    } else {
      for (let j = 0; j < category.sub.length; j++) {
        let sub_category = category.sub[j];
        if (sub_category.key === slug) {
          return sub_category.label;
        }
      }
    }
  }
}

export const getCustomTitle = (pivot, role) => {
  if (pivot.custom_title) {
    return pivot.custom_title;
  }

  let title = "";

  if (pivot.role) {
    if (pivot.role === "admin") {
      title = "Trưởng";
    } else if (pivot.role === "vice_admin") {
      title = "Phó";
    }
  }

  if (title) {
    title = title + " " + role.group_type;
  }

  return title;
}

export const getRoleClass = (alias) => {
  switch (alias) {
    case "admin":
      return "bg-red-500";
    case "editor":
      return "bg-blue-500";
    case "communication":
      return "bg-blue-700";
    case "creator":
      return "bg-pink-600";
    case "composer":
    case "artist":
    case "poet":
    case "recomposer":
      return "bg-green-700";
    case "collaborator":
      return "bg-yellow-500";
    default:
      return "";
  }
}

export const getActivityActionName = (action) => {
  switch (action) {
    case "update_lyric":
      return "cập nhật lời bài hát";
    case "comment":
      return "bình luận";
    case "love_comment":
      return "thích bình luận";
    case "create_sheet":
      return "tạo sheet nhạc";
    case "create_document":
      return "tạo tư liệu";
    case "create_song":
      return "tạo tân nhạc";
    case "create_folk":
      return "tạo dân ca";
    case "create_instrumental":
      return "tạo khí nhạc";
    case "create_karaoke":
      return "tạo thành viên hát";
    case "create_poem":
      return "tạo tiếng thơ";
    case "approve_upload":
      return "duyệt bản upload";
    case "reject_upload":
      return "từ chối bản upload";
    default:
      return "";
  }
}

export const ucfirst = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getAuthorText = (song) => {
  var tmp = [];

  if (song.sheet && song.sheet.composers && song.sheet.composers.data && song.sheet.composers.data.length) {
    song.sheet.composers.data.map((author) => {
      tmp.push(author.title);
    });
    return tmp.join(" - ");
  }

  if (song.recomposers && song.recomposers.data && song.recomposers.data.length) {
    song.recomposers.data.map((author) => {
      tmp.push(author.title);
    });
    return tmp.join(" - ");
  }

  if (song.poets && song.poets.data && song.poets.data.length) {
    song.poets.data.map((author) => {
      tmp.push(author.title);
    });
    return tmp.join(" - ");
  }

  return null;
}

export const getPerformerText = (song) => {
  var tmp = [];

  if (song.artists && song.artists.data && song.artists.data.length) {
    song.artists.data.map((performer) => {
      tmp.push(performer.title);
    });
    return tmp.join(" - ");
  }

  if (song.users && song.users.data && song.users.data.length) {
    song.users.data.map((performer) => {
      tmp.push(performer.username);
    });
    return tmp.join(" - ");
  }

  return null;
}

export const getDocumentTitle = (song) => {
  var title = song.title;
  var authorText = getAuthorText(song);
  if (authorText) {
    title = title + " (" + authorText + ")";
  }

  var performerText = getPerformerText(song);
  if (performerText) {
    title = title + " - " + performerText;
  }

  return title;
}

export const isLoggedIn = () => {
  let user = getUser();
  return user ? true : false;
}

export const daysRemaining = (targetDateTime) => {
  const targetDate = new Date(targetDateTime);
  const currentDate = new Date();
  const timeDifference = targetDate - currentDate;
  const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24)); // 1 ngày = 1000ms * 60s * 60p * 24h

  return daysRemaining;
}

export default {
  cutString,
  getWhereCondition,
  changeFavicon,
  openModal,
  closeModal,
  getSectionLessonCount,
  stripTags,
  daysRemaining,
  numberFormat
};
