import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import ApiService from "../src/core/services/api.service";
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import Multiselect from '@vueform/multiselect'
import "@vueform/multiselect/themes/default.css";
import vue3GoogleLogin from 'vue3-google-login'
import VueSocialSharing from 'vue-social-sharing'

// eslint-disable-next-line no-unused-vars
function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

// eslint-disable-next-line no-unused-vars
function subscribeUser(reg) {
  reg.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array('BCQoTwIdkxE6foIXgIOznYznq7zRS7sELufZiGjLRi3K3uAfddwjl_NtIc07pURKVOvJcjrHUsJeM1MmClwWtUA')
  }).then(function(sub) {
    storePushSubscription(sub);
  }).catch(function(e) {
    console.log("error...........", e);
  });
}

// eslint-disable-next-line no-unused-vars
function unsubscribeUser(reg) {
  console.log("unsubscribeUser................");
  reg.pushManager.getSubscription().then((sub) => {
    sub.unsubscribe().then(() => {
      console.log("unsubscribe success.......");
    });
  })
}

// eslint-disable-next-line no-unused-vars
function storePushSubscription(pushSubscription) {
  let query = `mutation($endpoint: String!, $auth: String!, $p256dh: String!) {
    subscribe(endpoint: $endpoint, auth: $auth, p256dh: $p256dh)
  }`;

  let data = JSON.parse(JSON.stringify(pushSubscription));

  ApiService.graphql(query, {endpoint: data.endpoint, auth: data.keys.auth, p256dh: data.keys.p256dh})
    .then()
    .catch((response) => {
      console.log(response);
    });
}

window.$ = window.jQuery = require('jquery');

const app = createApp(App);

app.use(router).mount('#app')

app.use(VueToast, {
  position: "top-right"
});

app.use(vue3GoogleLogin, {
  clientId: '454137046742-racuvmqlqadldcummhfcg1eerknrumq6.apps.googleusercontent.com'
})

app.use(VueSocialSharing);

app.component('v-pagination', VPagination);
app.component('multiselect', Multiselect);

if ('serviceWorker' in navigator) {
  window.addEventListener('load', function() {
  navigator.serviceWorker.register('/service-worker.js');
  });
}
